<template>
	<div class="d-flex align-center text-no-wrap" :style="disabled ? 'opacity: 0.2' : ''">
		<v-icon v-if="icon" small left color="grey">
			{{ icon }}
		</v-icon><span :class="cssclass"><slot /></span>
	</div>
</template>

<script>
/**********************************************************************************************
**** label generica con icona *****************************************************************
***********************************************************************************************
* testo nello slot
*/
export default {
	name: 'StandardLabel',
	components: { },
	props: {
		// classe css della label (default caption)
		cssclass :{
			type: String,
			default: () => {return 'caption'}
		},
		// tooltip della label
		title :{
			type: String,
			default: () => {return ''}
		},
		// icona della label
		icon :{
			type: String,
			default: () => {return ''}
		},
		// disabilita la label
		disabled :{
			type: Boolean,
			default: () => {return false}
		},
	},	
}
</script>

<style scoped lang="less">

</style>